<template>
  <section>
    <!-- <ul>
      <li v-for="group in refGroups" v-bind:key="group.id">{{group.id}}</li>
    </ul>-->

    <!-- <ul>
      <li v-for="group in refGroups" v-bind:key="group.id">{{group.songs}}</li>
    </ul>-->

    <!-- <div v-for="group in refGroups" v-bind:key="group.id">
      <div v-for="songs in group.songs" v-bind:key="songs.song_name">{{songs}}</div>
    </div>-->
    <!-- Desktop -->
    <div class="container files is-hidden-mobile">

      <h2 class="title" style="display: inline-block">Playback Group:</h2>

      
     
      <b-message v-show="this.refGroups.length == 0"
        ><b>It's quite empty here.</b> Add groups by entering a group name and
        press enter.</b-message
      >
      <div>
        <!-- Song-Group-Looper-->
        <div v-for="group in refGroups" v-bind:key="group.songs">
          <!-- Überschriften-->
          <b-message
            v-bind:title="group.group_name"
            aria-close-label="Close message"
            :closable="false"
          >
            
            <div class="b-table">
              <div class="table-wrapper has-mobile-cards">
                <table class="table">
                  <thead>
                    <tr>
                      <th style="width: 40px">ID</th>
                      <th style="width: 600px">Song Name</th>
                      <th>
                        <div class="th-wrap is-centered">Action</div>
                      </th>
                      <th>Player</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="songs in orderChildren(group.songs)"
                      v-bind:key="songs.song_name"
                    >
                      <td>{{ songs.song_id }}</td>
                      <td>{{ songs.song_name }}</td>
                      <td style="text-align: center">
                        
                      </td>
                      <td>
                        <b-button
                          type="is-dark"
                          rounded
                          icon-right="play"
                          @click="
                            PlaySong(
                              songs.song_src,
                              songs.song_name,
                              songs.song_id
                            );
                            CreatePlaylist(group.id);
                          "
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </b-message>
          <!-- Einzele Songs Pro Gruppe (2-tief, daher 2 mal loop)-->
        </div>
      </div>
      <Playbar
        :playstate="this.playback"
        :songname="this.playername"
        :playtime="this.playtime"
        :duration="this.duration"
        :barWidth="this.barWidth"
      />
    </div>
    <!-- Mobile -->
    <div class="container files is-hidden-desktop files_mobile">
      <h2 class="title">Playback Groups:</h2>
      <div>
        <!-- Song-Group-Looper-->
        <div v-for="group in refGroups" v-bind:key="group.songs">
          <!-- Überschriften-->
          <b-message
            v-bind:title="group.group_name"
            aria-close-label="Close message"
            :closable="false"
          >
            <div class="b-table">
              <div class="table-wrapper has-mobile-cards">
                <table class="table">
                  <thead>
                    <tr>
                      <th style="width: 600px">Song Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="songs in orderChildren(group.songs)"
                      v-bind:key="songs.song_name"
                    >
                      <td
                        style="cursor: pointer"
                        @click="
                          PlaySong(
                            songs.song_src,
                            songs.song_name,
                            songs.song_id
                          );
                          CreatePlaylist(group.id);
                        "
                      >
                        <b-icon icon="play"></b-icon>
                        {{ songs.song_name }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </b-message>
          <!-- Einzele Songs Pro Gruppe (2-tief, daher 2 mal loop)-->
        </div>
      </div>
      <Playbar
        :playstate="this.playback"
        :songname="this.playername"
        :playtime="this.playtime"
        :duration="this.duration"
        :barWidth="this.barWidth"
      />
    </div>
  </section>
</template>

<script>
import UploadModal from "@/components/UploadModal.vue";
import Playbar from "@/components/Playbar.vue";
import { bus } from "../main";
// eslint-disable-next-line
import db from "../main";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

export default {
  components: {
    UploadModal,
    Playbar,
  },

  methods: {
    fastForward() {
      this.player.currentTime += 15.0;
    },
    fastReverse() {
      this.player.currentTime -= 15.0;
    },
    sort() {
      //Sort
      var sort = function sortByKey(array, key) {
        return array.sort(function (a, b) {
          var x = a[key];
          var y = b[key];
          return x < y ? -1 : x > y ? 1 : 0;
        });
      };
      //Update
      if (this.orderByDate == false) {
        this.refGroups = sort(this.refGroups, "timestamp").reverse();
        this.orderByDate = true;
      } else {
        this.refGroups = sort(this.refGroups, "group_name");
        this.orderByDate = false;
      }
    },
    toast() {
      this.$buefy.toast.open({
        message: "Song successfully uploaded!",
        type: "is-success",
      });
    },
    ModalTrigger(id) {
      bus.$emit("ModalTrigger", true);
      this.group_id = id;
    },
    // eslint-disable-next-line
    PlaySong(src, sngname, id) {
      if (this.playback == false) {
        this.playback = true;
        this.player.src = src;
        this.playername = sngname;
        this.currentTrackIndex = id - 1;
        console.log(id);
        this.player.play();
      } else {
        this.playback = false;
        this.player.pause();
        this.player.src = src;
        this.playername = sngname;
        this.currentTrackIndex = id - 1;
        this.playback = true;
        this.player.play();
      }
    },
    CreatePlaylist(GroupId) {
      //Sucht nach GroupID der aktuellen Gruppe
      this.PlaylistId = this.refGroups.find((group) => {
        return group.id == GroupId;
      });
      //Object-Values werden in Array eingefügt
      let PlaylistUnsorted = Object.values(this.PlaylistId.songs);
      //Sortiert Array nach ID
      this.Playlist = PlaylistUnsorted.sort(function (a, b) {
        return a.song_id - b.song_id;
      });
    },
    PlayPause() {
      if (this.playback == false) {
        this.playback = true;
        this.player.play();
      } else {
        this.player.pause();
        this.playback = false;
      }
    },

    prevTrack() {
      if (this.currentTrackIndex > 0) {
        this.currentTrackIndex--;
      } else {
        this.currentTrackIndex = this.Playlist.length - 1;
      }
      this.currentTrack = this.Playlist[this.currentTrackIndex];
      this.playername = this.Playlist[this.currentTrackIndex].song_name;
      this.resetPlayer();
    },
    nextTrack() {
      if (this.currentTrackIndex < this.Playlist.length - 1) {
        this.currentTrackIndex++;
      } else {
        this.currentTrackIndex = 0;
      }
      this.currentTrack = this.Playlist[this.currentTrackIndex];
      this.playername = this.Playlist[this.currentTrackIndex].song_name;
      this.resetPlayer();
    },
    resetPlayer() {
      this.barWidth = 0;
      this.player.currentTime = 0;
      this.player.src = this.currentTrack.song_src;
      setTimeout(() => {
        if (this.playback) {
          this.player.play();
        } else {
          this.player.pause();
        }
      }, 300);
    },
    generateTime() {
      let width = (100 / this.player.duration) * this.player.currentTime;
      this.barWidth = width + "%";
      let durmin = Math.floor(this.player.duration / 60);
      let dursec = Math.floor(this.player.duration - durmin * 60);
      let curmin = Math.floor(this.player.currentTime / 60);
      let cursec = Math.floor(this.player.currentTime - curmin * 60);
      if (durmin < 10) {
        durmin = "0" + durmin;
      }
      if (dursec < 10) {
        dursec = "0" + dursec;
      }
      if (curmin < 10) {
        curmin = "0" + curmin;
      }
      if (cursec < 10) {
        cursec = "0" + cursec;
      }
      this.duration = durmin + ":" + dursec;
      this.playtime = curmin + ":" + cursec;
    },
    // eslint-disable-next-line
    updateBar(x) {
      let progress = this.progress;
      let maxduration = this.player.duration;
      let position = x - progress.offsetParent.offsetLeft;
      let percentage = (100 * position) / progress.offsetWidth;
      if (percentage > 100) {
        percentage = 100;
      }
      if (percentage < 0) {
        percentage = 0;
      }
      this.barWidth = percentage + "%";
      this.player.currentTime = (maxduration * percentage) / 100;

      //Check for playstate, start playback or not after position change
      if (this.playback == true) {
        this.player.play();
      } else {
        this.player.pause();
      }
    },
    orderChildren(id) {
      let data = id;
      let sorted = {};
      Object.keys(data)
        .sort()
        .forEach(function (key) {
          sorted[key] = data[key];
        });
      return sorted;
    },
  },
  data() {
    return {
      //Name for Playback-Groups
      group_name: "",
      //Object with Song Data
      refGroups: [],
      //Id of Playback-Group
      group_id: "",
      //Player Playback State
      playback: false,
      //Init Audio Player
      player: new Audio(),
      //Song-Display
      playername: "",
      //Array for Playlist-Data
      Playlist: [],
      //Currently Played time
      playtime: "",
      //Song Duration
      duration: "",
      //Progress-Bar Width
      barWidth: "",
      //Song-Progress for Playbar
      progress: "",
      //Playlist ID
      PlaylistId: null,
      //Track for Prev/Next
      currentTrack: null,
      //TrackIndex for Prev/Next
      currentTrackIndex: 0,
      //Abonnenten-Status
      subscriptionStatus: "",
      //Free Group Lengh
      freeGroupLength: 0,
      //OrderByDate or OrderByName
      orderByDate: true,
    };
  },
  beforeCreate() {
    this.userId = firebase.auth().currentUser.uid;
  },
  created() {
    firebase
      .firestore()
      .collection(this.$route.params.id)
      .doc(this.$route.params.groupId)
      .get()
      .then(async (doc) => {
        this.refGroups = [doc.data()];
      });
    

    bus.$on("ToastTrigger", this.toast);
    bus.$on("PlayPause", this.PlayPause);
    //Update every tick
    this.player.ontimeupdate = () => {
      //generawte playtime
      this.generateTime();
      bus.$on("clickProgress", async (e) => {
        this.player.pause();
        await bus.$on("progress", (f) => {
          this.progress = f;
        });
        this.updateBar(e.pageX);
      });
    };
    bus.$on("PrevSong", (a) => {
      if (a == true) {
        this.prevTrack();
      } else {
        null;
      }
    });
    bus.$on("NextSong", (a) => {
      if (a == true) {
        this.nextTrack();
      } else {
        null;
      }
    });
    bus.$on("FastForward", (a) => {
      if (a == true) {
        this.fastForward();
      } else {
        null;
      }
    });
    bus.$on("FastReverse", (a) => {
      if (a == true) {
        this.fastReverse();
      } else {
        null;
      }
    });
    this.player.onended = () => {
      this.nextTrack();
    };
  },
  computed: {
    myData() {
      return this.$data;
    },
  },
};
</script>

<style lang="scss">
.button_bg_dark {
  background-color: #404664;
  color: #f0f3fa;
  border-color: #404664;
}
.button_bg_dark:hover {
  background-color: #383d57;
  color: #f0f3fa;
  border-color: #383d57;
}
.button_bg_special {
  background-color: #6a73a0;
  border-color: #6a73a0;
  color: #f0f3fa;
}
.button_bg_special:hover {
  background-color: #586089;
  border-color: #586089;
  color: #f0f3fa;
}
.table {
  background-color: #f0f3fa !important;
}
.files {
  padding-top: 24px;
  margin-bottom: 60px;
}
.title {
  padding-top: 24px;
  color: #383d57;
}
.is-centered-table {
  text-align: center !important;
}
.message {
  margin-bottom: 36px;
}
.message-header {
  background-color: #383d57;
}
.message-body {
  background-color: #f0f3fa;
}
@media screen and (max-width: 768px) {
  .b-table
    .table-wrapper.has-mobile-cards
    tr:not(.detail):not(.is-empty):not(.table-footer)
    td {
    justify-content: flex-start !important;
  }
}

.b-table .table-wrapper.has-mobile-cards tr:not([class*="is-"]) {
  border-radius: 5px;
}
.files_mobile {
  margin-bottom: 150px;
  margin: 0 8px;
}
</style>
