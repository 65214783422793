<template>
  <div>
    <b-navbar
      class="has-shadow"
      :fixed-bottom="true"
      :mobile-burger="false"
      :is-active="true"
      :close-on-click="false"
    >
      <template slot="brand">
        <b-navbar-item class="mx-5">
          <b-button
            size="is-small"
            type="is-dark"
            rounded
            @click="FastReverse()"
            class="is-hidden-tablet"
            ><svg style="width: 16px; height: 16px" viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M12.5 3C17.15 3 21.08 6.03 22.47 10.22L20.1 11C19.05 7.81 16.04 5.5 12.5 5.5C10.54 5.5 8.77 6.22 7.38 7.38L10 10H3V3L5.6 5.6C7.45 4 9.85 3 12.5 3M10 12V22H8V14H6V12H10M12 12H18V14H14V16H16C17.11 16 18 16.9 18 18V20C18 21.11 17.11 22 16 22H12V20H16V18H12V12Z"
              /></svg
          ></b-button>
          <b-button
            type="is-dark"
            rounded
            icon-right="skip-previous"
            @click="PrevSong()"
          />

          <b-button
            v-if="this.playstate == true"
            type="is-dark"
            rounded
            icon-right="pause"
            @click="this.PlayPause"
          />
          <b-button
            v-else
            type="is-dark"
            rounded
            icon-right="play"
            @click="this.PlayPause"
          />
          <b-button
            type="is-dark"
            rounded
            icon-right="skip-next"
            @click="NextSong()"
          />
          <b-button
            size="is-small"
            type="is-dark"
            rounded
            @click="FastForward()"
            class="is-hidden-tablet"
            ><svg style="width: 16px; height: 16px" viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M11.5 3C14.15 3 16.55 4 18.4 5.6L21 3V10H14L16.62 7.38C15.23 6.22 13.46 5.5 11.5 5.5C7.96 5.5 4.95 7.81 3.9 11L1.53 10.22C2.92 6.03 6.85 3 11.5 3M10 12V22H8V14H6V12H10M12 12H18V14H14V16H16C17.11 16 18 16.9 18 18V20C18 21.11 17.11 22 16 22H12V20H16V18H12V12Z"
              /></svg
          ></b-button>
          <p class="mx-5 is-hidden-mobile" v-if="this.songname == ''">
            <i>Select Song...</i>
          </p>
          <p v-else class="mx-5 songname is-hidden-mobile">
            {{ this.songname }}
          </p>
        </b-navbar-item>
      </template>

      <template slot="start">
        <b-navbar-item class="mx-5 is-hidden-touch" style="flex-grow: 1">
          <b-field style="flex-grow: 1">
            <div>
              <div class="progress__bar" @click="clickProgress" ref="progress">
                <div
                  class="progress__current"
                  :style="{ width: barWidth }"
                ></div>
              </div>
            </div>
          </b-field>
        </b-navbar-item>
        <b-navbar-item class="mx-5">
          <span class="" v-if="playtime == ''">0:00/0:00</span>
          <span class="" v-else>{{ this.playtime }}/{{ this.duration }}</span>
          <span class="is-hidden-tablet ml-3" v-if="this.songname == ''">
            <i>Select Song...</i>
          </span>
          <span v-else class="songname is-hidden-tablet">
            {{ this.songname }}
          </span>
        </b-navbar-item>
      </template>
    </b-navbar>
  </div>
</template>

<script>
import { bus } from "../main";

export default {
  props: {
    playstate: { type: Boolean },
    songname: { type: String },
    playtime: { type: String },
    duration: { type: String },
    barWidth: { type: String },
  },
  methods: {
    PlayPause() {
      bus.$emit("PlayPause", true);
    },
    PrevSong() {
      bus.$emit("PrevSong", true);
    },
    NextSong() {
      bus.$emit("NextSong", true);
    },
    FastForward() {
      bus.$emit("FastForward", true);
    },
    FastReverse() {
      bus.$emit("FastReverse", true);
    },
    clickProgress(e) {
      bus.$emit("clickProgress", e);
      bus.$emit("progress", this.$refs.progress);
    },
  },
};
</script>

<style>
@media (max-width: 768px) {
  a.navbar-item:focus,
  a.navbar-item:focus-within,
  a.navbar-item:hover,
  a.navbar-item.is-active,
  .navbar-link:focus,
  .navbar-link:focus-within,
  .navbar-link:hover,
  .navbar-link.is-active {
    color: initial !important;
  }

  .navbar-menu {
    display: flex;
  }
  .navbar-brand {
    width: 100%;
  }

  .navbar {
    flex-wrap: wrap;
  }

  .navbar-brand {
    justify-content: center;
  }
}

.ml-1 {
  margin-left: 1rem;
}
.mx-5 {
  margin: 0 1.5rem 0 1.5rem;
}
.navbar-brand a.navbar-item:focus,
.navbar-brand a.navbar-item:hover {
  background-color: initial;
}
.songname {
  font-weight: 800;
}

.navbar-start {
  flex-grow: 1 !important;
}

.progress {
  width: 100%;
  user-select: none;
}
.progress__top {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.progress__duration {
  color: #71829e;
  font-weight: 700;
  font-size: 20px;
  opacity: 0.5;
}
.progress__time {
  margin-top: 2px;
  color: #71829e;
  font-weight: 700;
  font-size: 16px;
  opacity: 0.7;
}

.progress__bar {
  height: 12px;
  width: 100%;
  cursor: pointer;
  background-color: #a3b3ce;
  display: inline-block;
  border-radius: 10px;
}

.progress__current {
  height: inherit;
  width: 0%;
  background-color: #4a4a4a;
  border-radius: 10px;
}
a.navbar-item:focus,
a.navbar-item:focus-within,
a.navbar-item:hover,
a.navbar-item.is-active,
.navbar-link:focus,
.navbar-link:focus-within,
.navbar-link:hover,
.navbar-link.is-active {
  background-color: initial;
  color: initial;
}
</style>
