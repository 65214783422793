<template>
  <div class="home">
    
    <Header/>
    <Files/>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue"
import Files from "@/components/Files.vue"

export default {
  name: "Home",
  components: {
    Header,
    Files,
  }
};
</script>
