<template>
  <div id="app">
    <Header />
    <router-view />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
@import url("https://cdn.materialdesignicons.com/5.3.45/css/materialdesignicons.min.css");
@import url("https://fonts.googleapis.com/css2?family=B612:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap");
@import "~bulma/sass/utilities/_all";

$primary: #ff7575;
$secondary: #537ca3;
$danger: #ff4365;
$light: #f0f3fa;
$primary-invert: findColorInvert($primary);
$info: #586089;
$colors: (
  "white": (
    $white,
    $black,
  ),
  "black": (
    $black,
    $white,
  ),
  "light": (
    $light,
    $light-invert,
  ),
  "dark": (
    $dark,
    $dark-invert,
  ),
  "primary": (
    $primary,
    $primary-invert,
  ),
  "info": (
    $info,
    $info-invert,
  ),
  "success": (
    $success,
    $success-invert,
  ),
  "warning": (
    $warning,
    $warning-invert,
  ),
  "danger": (
    $danger,
    $danger-invert,
  ),
);

h2,
p {
  font-family: Open Sans;
}

@import "~bulma";
@import "~buefy/src/scss/buefy";
</style>
