<template>
  <footer class="footer">
    <div class="content has-text-centered">
      <p>
        <strong>Refplay</strong> by
        <a target="_blank" href="https://enstruments.wtf">Enstruments</a> | ©
        <span v-text="new Date().getFullYear()"></span> |
        <a target="_blank" href="https://refplay.app/terms-und-conditions.html"
          >Terms & Condition</a
        >
        |
        <a
          target="_blank"
          href="https://refplay.app/refplay-privacy-notice.html"
          >Privacy Policy</a
        >
      </p>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style>
footer {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  z-index: 30;
}
</style>
