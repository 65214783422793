<template>
  <div>
    <nav class="navbar" role="navigation" aria-label="main navigation">
      <div class="navbar-brand">
        <a class="navbar-item" href="/">
          <span id="refplay">
            REFPLAY
            <span
              style="vertical-align: text-top"
              class="tag is-warning is-light"
              >Beta</span
            >
          </span>
        </a>

        <a
          role="button"
          class="navbar-burger burger"
          aria-label="menu"
          aria-expanded="false"
          @click="isMenuModalActive = true"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div id="navbarBasicExample" class="navbar-menu is-hidden-mobile">
        <div class="navbar-end">
          <!-- <a class="navbar-item">Settings</a> -->
          <div class="navbar-item">
            <a href="/settings">
              <b-button
                v-show="this.$router.currentRoute.path == '/'"
                class="mr-3"
                type="is-light"
                icon-right="cog"
              ></b-button
            ></a>
            <a href="/"
              ><b-button
                v-show="this.$router.currentRoute.path == '/settings'"
                class="mr-3"
                type="is-light"
                icon-right="undo"
                >Back to main</b-button
              ></a
            >
            <b-button v-on:click="logout" type="is-light">Log Out</b-button>
          </div>
        </div>
      </div>
    </nav>
    <b-modal :active.sync="isMenuModalActive">
      <div class="card">
        <div class="card-content">
          <div class="content">
            <h2>Log out?</h2>
            <b-button v-on:click="logout" type="is-light">Log Out</b-button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
document.addEventListener("DOMContentLoaded", () => {
  // Get all "navbar-burger" elements
  const $navbarBurgers = Array.prototype.slice.call(
    document.querySelectorAll(".navbar-burger"),
    0
  );

  // Check if there are any navbar burgers
  if ($navbarBurgers.length > 0) {
    // Add a click event on each of them
    $navbarBurgers.forEach((el) => {
      el.addEventListener("click", () => {
        // Get the target from the "data-target" attribute
        const target = el.dataset.target;
        const $target = document.getElementById(target);

        // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
        el.classList.toggle("is-active");
        $target.classList.toggle("is-active");
      });
    });
  }
});
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

export default {
  data() {
    return {
      isMenuModalActive: false,
    };
  },
  methods: {
    logout: function () {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.go({ path: this.$router.path });
        });
    },
    modalMenu: () => {
      console.log("ModalMenu");
    },
  },
};
</script>

<style lang="scss" scoped>
#refplay {
  font-size: 2em;
  font-weight: 800;
  color: #383d57;
}

.tag {
  font-size: 0.55rem;
}
</style>
