<template>
  <div id="login_bg">
    <div class="container">
      <div class="card">
        <b-icon icon="account" size="is-large"></b-icon>
        <h3>Sign in to your account</h3>
        <form @submit.prevent="login">
          <div class="login">
            <label>Email</label>
            <input type="text" v-model="email" required />
          </div>
          <div class="password">
            <label>Password</label>
            <input type="password" v-model="password" required />
          </div>
          <button>Sign In</button>
        </form>

        <a href="/register">Register</a>
        |
        <a href="/reset">Forgot Password</a>
        <b-message type="is-danger is-light" v-if="error">{{
          error
        }}</b-message>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
export default {
  data() {
    return {
      email: "",
      password: "",
      error: "",
    };
  },
  methods: {
    login() {
      firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then((data) => {
          console.log(data);
          this.$router.replace({ path: "/" });
        })
        .catch((error) => {
          if (error.code == "auth/wrong-password") {
            this.error = "Incorrect Password";
          } else if (error.code == "auth/invalid-email") {
            this.error = "Incorrect E-Mail";
          } else if (error.code == "auth/user-not-found") {
            this.error = "This account does not exist.";
          } else {
            this.error = error.message;
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  width: 350px;
  margin: 0 auto;
  padding: 16px 24px 48px 24px;
  border-radius: 0 0 5px 5px;
  -webkit-box-shadow: 10px 10px 69px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 69px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 69px 0px rgba(0, 0, 0, 0.75);
}
#login_bg {
  background: url(../assets/login_db.jpg);
  height: 100vh;
  background-position: bottom center;
  background-size: cover;
}

h3 {
  padding-bottom: 24px;
  font-weight: 1000;
  font-size: 2em;
}

div.password {
  margin: 24px 0 24px 0;
}

button {
  padding: 1rem 2rem !important;
  font-size: 0.8rem !important;
  color: #fff;
  background-color: #6a73a0;
  border: 1px solid #6a73a0;
  border-radius: 5px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  margin-bottom: 16px;
}

button:hover {
  cursor: pointer;
}

input {
  background: #fff;
  border-color: #e6e6e6;
  color: #555;
  padding: 1rem 1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: initial;
  border: 1px solid #ced4da;
  border-top-color: rgb(206, 212, 218);
  border-top-width: 1px;
  border-right-color: rgb(206, 212, 218);
  border-right-width: 1px;
  border-bottom-color: rgb(206, 212, 218);
  border-bottom-width: 1px;
  border-left-color: rgb(206, 212, 218);
  border-left-width: 1px;
  border-top-color: rgb(206, 212, 218);
  border-top-width: 1px;
  border-right-color: rgb(206, 212, 218);
  border-right-width: 1px;
  border-bottom-color: rgb(206, 212, 218);
  border-bottom-width: 1px;
  border-left-color: rgb(206, 212, 218);
  border-left-width: 1px;
  width: 100%;
  transition: background-color 0.2s linear;
}

input:focus {
  background: #f9f9f9;
  border-color: #e6e6e6;
}
label,
a {
  display: inline-block;
  margin-bottom: 0.5rem;
  font-size: 0.8rem;
  color: #555;
}
//Theme
</style>
