<template>
  <div id="register_bg">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8">
          <div class="card">
            <b-icon icon="account" size="is-large"></b-icon>
            <h3>Create a new account</h3>
            <div class="card-body">
              <b-message type="is-danger is-light" v-if="error">{{
                error
              }}</b-message>
              <form action="#" @submit.prevent="submitCheck">
                <div class="form-group row">
                  <label
                    for="name"
                    class="col-md-4 col-form-label text-md-right"
                    >Name</label
                  >

                  <div class="col-md-6">
                    <input
                      id="name"
                      type="name"
                      class="form-control"
                      name="name"
                      value
                      required
                      autofocus
                      v-model="form.name"
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label
                    for="email"
                    class="col-md-4 col-form-label text-md-right"
                    >Email</label
                  >

                  <div class="col-md-6">
                    <input
                      id="email"
                      type="email"
                      class="form-control"
                      name="email"
                      value
                      required
                      autofocus
                      v-model="form.email"
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label
                    for="password"
                    class="col-md-4 col-form-label text-md-right"
                    >Password</label
                  >

                  <div class="col-md-6">
                    <input
                      id="password"
                      type="password"
                      class="form-control"
                      name="password"
                      required
                      v-model="form.password"
                      @input="checkPassword"
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label
                    for="password"
                    class="col-md-4 col-form-label text-md-right"
                    >Subscription</label
                  >

                  <div class="col-md-6">
                    <select v-model="form.subscription">
                      <option disabled>Please select one</option>
                      <option value="a">Free</option>
                      <option value="b">7,99 Monthly</option>
                      <option value="c">69,99 Yearly</option>
                    </select>
                  </div>
                </div>

                <div class="form-group row mb-0">
                  <div class="col-md-8 offset-md-4">
                    <button :disabled="form.disableSubmit" type="submit">
                      Register
                    </button>
                  </div>
                </div>
              </form>
              <a href="/login">Sign in instead</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { loadScript } from "vue-plugin-load-script";

export default {
  //This is prob. useless and can be removed
  beforeCreated() {
    this.userId = firebase.auth().currentUser.uid;
  },

  data() {
    return {
      form: {
        name: "",
        email: "",
        password: "",
        subscription: "Please select one",
        disableSubmit: true,
      },
      error: null,
      userID: "",
      subscriptionStatus: "",
      subscriptionId: "",
    };
  },
  mounted() {
    //UX
    (function (h, o, t, j, a, r) {
      h.hj =
        h.hj ||
        function () {
          (h.hj.q = h.hj.q || []).push(arguments);
        };
      h._hjSettings = { hjid: 2841550, hjsv: 6 };
      a = o.getElementsByTagName("head")[0];
      r = o.createElement("script");
      r.async = 1;
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
    })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");

    //Paddle
    loadScript("https://cdn.paddle.com/paddle/paddle.js")
      .then(() => {
        Paddle.Setup({
          vendor: 124219,
          eventCallback: (data) => {
            // The data.event will specify the event type
            if (data.event === "Checkout.Complete") {
              console.log(data.eventData); // Data specifics on the event
              if (!data.eventData.flagged) {
                let checkoutId = data.eventData.checkout.id;
                Paddle.Order.details(checkoutId, function (data) {
                  // Order data, downloads, receipts etc... available within 'data' variable.
                  this.subscriptionId = data.subscriptionId;
                });
              }
              this.submit();
            } else if (data.event === "Checkout.Close") {
              console.log(data.eventData); // Data specifics on the event
            }
          },
        });
      })
      .catch(() => {
        // Failed to fetch script
      });
  },
  created() {
    if (this.$route.query.sub != undefined) {
      this.form.subscription = this.$route.query.sub;
    }
  },

  methods: {
    checkPassword() {
      let password_length = this.form.password.length;

      if (password_length > 6) {
        this.error = "";
        this.form.disableSubmit = false;
      } else {
        this.error = "Password lengh must be 6 or more characters.";
      }
    },
    submitCheck() {
      if (this.form.subscription == "a") {
        this.subscriptionStatus = "free";
        this.submit();
      } else if (this.form.subscription == "b") {
        this.subscriptionStatus = 658306;
        Paddle.Checkout.open({
          product: 658306,
          email: this.form.email,
          passthrough: '{"user_id": this.userId}',
        });
      } else {
        this.subscriptionStatus = 658305;
        Paddle.Checkout.open({
          product: 658305,
          email: this.form.email,
          passthrough: '{"user_id": this.userId}',
        });
      }
    },

    submit() {
      firebase
        .auth()
        .createUserWithEmailAndPassword(this.form.email, this.form.password)
        .then((data) => {
          this.userId = data.user.uid;
          data.user
            .updateProfile({
              displayName: this.form.name,
            })
            .then(() => {});
        })
        .then(async (data) => {
          console.log(data);
          await firebase.firestore().collection("users").doc(this.userId).set({
            name: this.form.name,
            email: this.form.email,
            subscriptionStatus: this.subscriptionStatus,
            subscriptionId: this.subscriptionId,
          });

          //Init Collection - Not needed

          // await firebase
          //   .firestore()
          //   .collection(this.userId)
          //   .doc("INIT")
          //   .set({
          //     //Song ID
          //     ["songs.song_" + 1 + ".song_id"]: 1,
          //     //Song Name
          //     ["songs.song_" + 1 + ".song_name"]: "Init",
          //     //Song Src
          //     ["songs.song_" + 1 + ".song_src"]: "Init"
          //   });
          // await firebase
          //   .firestore()
          //   .collection(this.userId)
          //   .doc("INIT")
          //   .delete();
          if (this.subscriptionStatus == 658306) {
            let signal = fetch("https://signal.e-57.de/refplay/signup/Monthly/");
          } else if (this.subscriptionStatus == 658305) {
            let signal = fetch("https://signal.e-57.de/refplay/signup/Yearly/");
          } else if (this.subscriptionStatus) {
            let signal = fetch("https://signal.e-57.de/refplay/signup/Free/");
          }
          this.$router.replace({ path: "/" });
        })
        .catch((error) => {
          if (error.code == "auth/email-already-exists") {
            this.error = "This E-Mail already exists.";
          } else if (error.code == "auth/invalid-email") {
            this.error = "Invalid E-Mail";
          } else if (error.code == "auth/email-already-in-use") {
            this.error =
              "The email address is already in use by another account.";
          } else {
            this.error = error.message;
          }
        });
    },
  },
};
</script>

<style scoped>
.card {
  width: 350px;
  margin: 0 auto;
  padding: 16px 24px 48px 24px;
  border-radius: 0 0 5px 5px;
  -webkit-box-shadow: 10px 10px 69px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 69px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 69px 0px rgba(0, 0, 0, 0.75);
}
#register_bg {
  background: url(../assets/register_bg.jpg);
  height: 100vh;
  background-position: bottom center;
  background-size: cover;
}

h3 {
  padding-bottom: 24px;
  font-weight: 1000;
  font-size: 2em;
}

button {
  padding: 1rem 2rem !important;
  font-size: 0.8rem !important;
  color: #fff;
  background-color: #6a73a0;
  border: 1px solid #6a73a0;
  border-radius: 5px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  margin-bottom: 16px;
}
button:hover {
  cursor: pointer;
}

input,
select {
  background: #fff;
  border-color: #e6e6e6;
  color: #555;
  padding: 1rem 1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: initial;
  border: 1px solid #ced4da;
  border-top-color: rgb(206, 212, 218);
  border-top-width: 1px;
  border-right-color: rgb(206, 212, 218);
  border-right-width: 1px;
  border-bottom-color: rgb(206, 212, 218);
  border-bottom-width: 1px;
  border-left-color: rgb(206, 212, 218);
  border-left-width: 1px;
  border-top-color: rgb(206, 212, 218);
  border-top-width: 1px;
  border-right-color: rgb(206, 212, 218);
  border-right-width: 1px;
  border-bottom-color: rgb(206, 212, 218);
  border-bottom-width: 1px;
  border-left-color: rgb(206, 212, 218);
  border-left-width: 1px;
  width: 100%;
  transition: background-color 0.2s linear;
  margin-bottom: 16px;
}

input:focus {
  background: #f9f9f9;
  border-color: #e6e6e6;
}
label,
a {
  display: inline-block;
  margin-bottom: 0.5rem;
  font-size: 0.8rem;
  color: #555;
}
</style>
