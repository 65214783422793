import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { firestorePlugin } from "vuefire";
import Buefy from "buefy";

Vue.config.productionTip = false;
Vue.use(Buefy);
Vue.use(firestorePlugin);

const configOptions = {
  apiKey: "AIzaSyDhbK9Bt12w7hU0prbzjbmKO0XRekyR3qo",
  authDomain: "refplay-55fe3.firebaseapp.com",
  databaseURL: "https://refplay-55fe3.firebaseio.com",
  projectId: "refplay-55fe3",
  storageBucket: "refplay-55fe3.appspot.com",
  messagingSenderId: "787268030491",
  appId: "1:787268030491:web:1a2768d610ec8bbb0ae8be",
};

firebase.initializeApp(configOptions);
export const bus = new Vue();

firebase.auth().onAuthStateChanged((user) => {
  store.dispatch("fetchUser", user);
});

export default firebase.firestore();

//Login-State bleibt bei Reload
let app;
firebase.auth().onAuthStateChanged(function() {
  if (!app) {
    /* eslint-disable no-new */
    app = new Vue({
      router,
      store,
      render: (h) => h(App),
    }).$mount("#app");
  }
});
