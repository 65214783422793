<template>
  <section>
    <!-- <ul>
      <li v-for="group in refGroups" v-bind:key="group.id">{{group.id}}</li>
    </ul>-->

    <!-- <ul>
      <li v-for="group in refGroups" v-bind:key="group.id">{{group.songs}}</li>
    </ul>-->

    <!-- <div v-for="group in refGroups" v-bind:key="group.id">
      <div v-for="songs in group.songs" v-bind:key="songs.song_name">{{songs}}</div>
    </div>-->
    <!-- Desktop -->
    <div class="container files is-hidden-mobile">
      <h2 class="title">Add Groups:</h2>
      <b-message
        v-show="this.refGroups.length == 1 && this.subscriptionStatus == 'free'"
        >You have reached the maximum amount of groups.
        <b><a href="/settings">Upgrade your subscription</a></b> if you need
        more.</b-message
      >
      <b-message
        v-show="freeGroupLength == 3 && this.subscriptionStatus == 'free'"
        >You have reached the maximum amount of songs.
        <b><a href="/settings">Upgrade your subscription</a></b> if you need
        more.</b-message
      >
      <form
        v-show="this.subscriptionStatus != 'free' || this.refGroups.length < 1"
      >
        <b-field>
          <b-input
            expanded
            placeholder="Add Group Name"
            type="search"
            icon="plus-circle-outline"
            v-model.trim="group_name"
          ></b-input>
          <p class="control">
            <button class="button button_bg_dark" @click.prevent="AddGroup">
              Add Group
            </button>
          </p>
        </b-field>
      </form>
      <h2 class="title" style="display: inline-block">Playback Groups:</h2>

      <b-button
        @click="sort()"
        class="mr-3 is-pulled-right mt-5"
        type="is-light"
        icon-right="sort-alphabetical-ascending"
        v-if="orderByDate == true"
        >Sort by Name</b-button
      >
      <b-button
        @click="sort()"
        class="mr-3 is-pulled-right mt-5"
        type="is-light"
        icon-right="calendar-clock"
        v-else
        >Sort by Date</b-button
      >
      <b-message v-show="this.refGroups.length == 0"
        ><b>It's quite empty here.</b> Add groups by entering a group name and
        press enter.</b-message
      >
      <div>
        <!-- Song-Group-Looper-->
        <div v-for="group in refGroups" v-bind:key="group.songs">
          <!-- Überschriften-->
          <b-message
            v-bind:title="group.group_name"
            aria-close-label="Close message"
            :closable="false"
          >
            <button
              v-show="subscriptionStatus != 'free' || freeGroupLength < 3"
              class="button field button_bg_special"
              @click="ModalTrigger(group.id)"
            >
              <b-icon icon="file-upload-outline"></b-icon>
              <span>Upload Files</span>
            </button>
            <button
              style="margin-left: 16px"
              class="button field is-dark is-danger"
              @click="DeleteGroup(group.id)"
            >
              <b-icon icon="delete-outline"></b-icon>
              <span>DeleteGroup</span>
            </button>
                        <button
              style="margin-left: 16px"
              class="button field is-dark"
              @click="ShareGroup(group.id)"
            >
              <b-icon icon="export"></b-icon>
              <span>Share Group</span>
            </button>
            <div class="b-table">
              <div class="table-wrapper has-mobile-cards">
                <table class="table">
                  <thead>
                    <tr>
                      <th style="width: 40px">ID</th>
                      <th style="width: 600px">Song Name</th>
                      <th>
                        <div class="th-wrap is-centered">Action</div>
                      </th>
                      <th>Player</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="songs in orderChildren(group.songs)"
                      v-bind:key="songs.song_name"
                    >
                      <td>{{ songs.song_id }}</td>
                      <td>{{ songs.song_name }}</td>
                      <td style="text-align: center">
                        <b-button
                          type="is-danger"
                          outlined
                          @click="
                            DeleteSong(group.id, songs.song_id, songs.song_src)
                          "
                          >Delete Song</b-button
                        >
                      </td>
                      <td>
                        <b-button
                          type="is-dark"
                          rounded
                          icon-right="play"
                          @click="
                            PlaySong(
                              songs.song_src,
                              songs.song_name,
                              songs.song_id
                            );
                            CreatePlaylist(group.id);
                          "
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </b-message>
          <!-- Einzele Songs Pro Gruppe (2-tief, daher 2 mal loop)-->
        </div>
      </div>
       <b-modal v-model="isShareModalActive">
            <div class="card">
                <div class="card-content">
                    <div class="content">
                      <h2>Sharing your Song-Group</h2>
Your group is now available for everyone:
                        <a>{{ sharingLink }}</a>
                        <br>
                        <small><a style="color:#ff365b" @click="RemoveShareGroup()">Make private again</a></small>
                    </div>
                </div>
            </div>
        </b-modal>
      <UploadModal
        :group_name="group_id"
        :uid="this.userId"
        :subscriptionStatus="subscriptionStatus"
      />
      <Playbar
        :playstate="this.playback"
        :songname="this.playername"
        :playtime="this.playtime"
        :duration="this.duration"
        :barWidth="this.barWidth"
      />
    </div>
    <!-- Mobile -->
    <div class="container files is-hidden-desktop files_mobile">
      <h2 class="title">Playback Groups:</h2>
      <div>
        <!-- Song-Group-Looper-->
        <div v-for="group in refGroups" v-bind:key="group.songs">
          <!-- Überschriften-->
          <b-message
            v-bind:title="group.group_name"
            aria-close-label="Close message"
            :closable="false"
          >
            <div class="b-table">
              <div class="table-wrapper has-mobile-cards">
                <table class="table">
                  <thead>
                    <tr>
                      <th style="width: 600px">Song Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="songs in orderChildren(group.songs)"
                      v-bind:key="songs.song_name"
                    >
                      <td
                        style="cursor: pointer"
                        @click="
                          PlaySong(
                            songs.song_src,
                            songs.song_name,
                            songs.song_id
                          );
                          CreatePlaylist(group.id);
                        "
                      >
                        <b-icon icon="play"></b-icon>
                        {{ songs.song_name }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </b-message>
          <!-- Einzele Songs Pro Gruppe (2-tief, daher 2 mal loop)-->
        </div>
      </div>
     
      <UploadModal
        :subscriptionStatus="subscriptionStatus"
        :group_name="group_id"
        :uid="this.userId"
      />
      <Playbar
        :playstate="this.playback"
        :songname="this.playername"
        :playtime="this.playtime"
        :duration="this.duration"
        :barWidth="this.barWidth"
      />
    </div>
  </section>
</template>

<script>
import UploadModal from "@/components/UploadModal.vue";
import Playbar from "@/components/Playbar.vue";
import { bus } from "../main";
// eslint-disable-next-line
import db from "../main";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

export default {
  components: {
    UploadModal,
    Playbar,
  },

  methods: {
    fastForward() {
      this.player.currentTime += 15.0;
    },
    fastReverse() {
      this.player.currentTime -= 15.0;
    },
    sort() {
      //Sort
      var sort = function sortByKey(array, key) {
        return array.sort(function (a, b) {
          var x = a[key];
          var y = b[key];
          return x < y ? -1 : x > y ? 1 : 0;
        });
      };
      //Update
      if (this.orderByDate == false) {
        this.refGroups = sort(this.refGroups, "timestamp").reverse();
        this.orderByDate = true;
      } else {
        this.refGroups = sort(this.refGroups, "group_name");
        this.orderByDate = false;
      }
    },
    toast() {
      this.$buefy.toast.open({
        message: "Song successfully uploaded!",
        type: "is-success",
      });
    },
    ModalTrigger(id) {
      bus.$emit("ModalTrigger", true);
      this.group_id = id;
    },
    // eslint-disable-next-line
    DeleteSong(groupId, songId, songSrc) {
      firebase
        .firestore()
        .collection(this.userId)
        .doc(groupId)
        .update({
          ["songs.song_" + songId]: firebase.firestore.FieldValue.delete(),
        });
      //Delete Files from Storage (Disabled, da keine uniquen Filenames)
      // let deleto = firebase.storage().refFromURL(songSrc);
      // // Delete the file
      // deleto
      //   .delete()
      //   .then(function() {
      //     // File deleted successfully
      //     console.log("File deleted");
      //   })
      //   .catch(function(error) {
      //     // Uh-oh, an error occurred!
      //     console.log(error);
      //   });

      //Update GroupNames
      firebase
        .firestore()
        .collection(this.userId)
        .doc(groupId)
        .get()
        .then(async (doc) => {
          if (doc.exists) {
            //Object mit Songs
            let songgroup = doc.data().songs;
            //Array mit Song-Name-Keys
            let songs_unsorted = Object.keys(doc.data().songs);
            //-> sortiert
            let songs = songs_unsorted.sort();
            //Array loopen
            await songs.forEach((group_name, index) => {
              //Obj-Namen ändern
              if (group_name != "song_" + (index + 1)) {
                let obj = songgroup;
                obj["song_" + (index + 1)] = obj[group_name];
                delete obj[group_name]; // Delete old key

                console.log(obj);
                firebase
                  .firestore()
                  .collection(this.userId)
                  .doc(groupId)
                  .update({
                    //Song ID
                    songs: obj,
                  });
              }
            });
          } else {
            console.log("Error. Doc does not exist");
          }
          //Update Song_Ids
          firebase
            .firestore()
            .collection(this.userId)
            .doc(groupId)
            .get()
            .then(async (doc) => {
              if (doc.exists) {
                let song_id_unsorted = Object.keys(doc.data().songs);
                let song_id = song_id_unsorted.sort();
                song_id.forEach((song_obj_id, index) => {
                  firebase
                    .firestore()
                    .collection(this.userId)
                    .doc(groupId)
                    .update({
                      //Song ID
                      ["songs." + song_obj_id + ".song_id"]: index + 1,
                    });
                });
              } else {
                console.log("Error. Doc does not exist");
              }
            });
        });
    },
    DeleteGroup(docId) {
      firebase.firestore().collection(this.userId).doc(docId).delete();
    },

    ShareGroup(docId) {
      this.sharingGroup = docId
          if (this.refGroups.filter(group => group.isShared == true && group.id == docId).length != 0){
            this.sharingLink = "https://play.refplay.app/share/" + this.userId + "/" + docId
            this.isShareModalActive = true
          }
    else {firebase.firestore().collection(this.userId).doc(docId).set(
        {
          isShared: true
        }, { merge: true }
      );
      this.sharingLink = "https://play.refplay.app/share/" + this.userId + "/" + docId
            this.isShareModalActive = true
      }
      
    },
    RemoveShareGroup() {
         firebase.firestore().collection(this.userId).doc(this.sharingGroup).set(
        {
          isShared: false
        }, { merge: true }
      );
      this.sharingLink = "Private again!"
    },
    PlaySong(src, sngname, id) {
      if (this.playback == false) {
        this.playback = true;
        this.player.src = src;
        this.playername = sngname;
        this.currentTrackIndex = id - 1;
        console.log(id);
        this.player.play();
      } else {
        this.playback = false;
        this.player.pause();
        this.player.src = src;
        this.playername = sngname;
        this.currentTrackIndex = id - 1;
        this.playback = true;
        this.player.play();
      }
    },
    AddGroup() {
      db.collection(this.userId).add({
        group_name: this.group_name,
        songs: {},
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      });
      this.group_name = "";
    },
    CreatePlaylist(GroupId) {
      //Sucht nach GroupID der aktuellen Gruppe
      this.PlaylistId = this.refGroups.find((group) => {
        return group.id == GroupId;
      });
      //Object-Values werden in Array eingefügt
      let PlaylistUnsorted = Object.values(this.PlaylistId.songs);
      //Sortiert Array nach ID
      this.Playlist = PlaylistUnsorted.sort(function (a, b) {
        return a.song_id - b.song_id;
      });
    },
    PlayPause() {
      if (this.playback == false) {
        this.playback = true;
        this.player.play();
      } else {
        this.player.pause();
        this.playback = false;
      }
    },

    prevTrack() {
      if (this.currentTrackIndex > 0) {
        this.currentTrackIndex--;
      } else {
        this.currentTrackIndex = this.Playlist.length - 1;
      }
      this.currentTrack = this.Playlist[this.currentTrackIndex];
      this.playername = this.Playlist[this.currentTrackIndex].song_name;
      this.resetPlayer();
    },
    nextTrack() {
      if (this.currentTrackIndex < this.Playlist.length - 1) {
        this.currentTrackIndex++;
      } else {
        this.currentTrackIndex = 0;
      }
      this.currentTrack = this.Playlist[this.currentTrackIndex];
      this.playername = this.Playlist[this.currentTrackIndex].song_name;
      this.resetPlayer();
    },
    resetPlayer() {
      this.barWidth = 0;
      this.player.currentTime = 0;
      this.player.src = this.currentTrack.song_src;
      setTimeout(() => {
        if (this.playback) {
          this.player.play();
        } else {
          this.player.pause();
        }
      }, 300);
    },
    generateTime() {
      let width = (100 / this.player.duration) * this.player.currentTime;
      this.barWidth = width + "%";
      let durmin = Math.floor(this.player.duration / 60);
      let dursec = Math.floor(this.player.duration - durmin * 60);
      let curmin = Math.floor(this.player.currentTime / 60);
      let cursec = Math.floor(this.player.currentTime - curmin * 60);
      if (durmin < 10) {
        durmin = "0" + durmin;
      }
      if (dursec < 10) {
        dursec = "0" + dursec;
      }
      if (curmin < 10) {
        curmin = "0" + curmin;
      }
      if (cursec < 10) {
        cursec = "0" + cursec;
      }
      this.duration = durmin + ":" + dursec;
      this.playtime = curmin + ":" + cursec;
    },
    // eslint-disable-next-line
    updateBar(x) {
      let progress = this.progress;
      let maxduration = this.player.duration;
      let position = x - progress.offsetParent.offsetLeft;
      let percentage = (100 * position) / progress.offsetWidth;
      if (percentage > 100) {
        percentage = 100;
      }
      if (percentage < 0) {
        percentage = 0;
      }
      this.barWidth = percentage + "%";
      this.player.currentTime = (maxduration * percentage) / 100;

      //Check for playstate, start playback or not after position change
      if (this.playback == true) {
        this.player.play();
      } else {
        this.player.pause();
      }
    },
    orderChildren(id) {
      let data = id;
      let sorted = {};
      Object.keys(data)
        .sort()
        .forEach(function (key) {
          sorted[key] = data[key];
        });
      return sorted;
    },
  },
  data() {
    return {
      //Name for Playback-Groups
      group_name: "",
      //Object with Song Data
      refGroups: [],
      //Id of Playback-Group
      group_id: "",
      //Player Playback State
      playback: false,
      //Init Audio Player
      player: new Audio(),
      //Song-Display
      playername: "",
      //Array for Playlist-Data
      Playlist: [],
      //Currently Played time
      playtime: "",
      //Song Duration
      duration: "",
      //Progress-Bar Width
      barWidth: "",
      //Song-Progress for Playbar
      progress: "",
      //Playlist ID
      PlaylistId: null,
      //Track for Prev/Next
      currentTrack: null,
      //TrackIndex for Prev/Next
      currentTrackIndex: 0,
      //Abonnenten-Status
      subscriptionStatus: "",
      //Free Group Lengh
      freeGroupLength: 0,
      //OrderByDate or OrderByName
      orderByDate: true,
      //SharingModel
      isShareModalActive: false,
      sharingLink: '',
      sharingGroup: '',
    };
  },
  beforeCreate() {
    this.userId = firebase.auth().currentUser.uid;
  },
  created() {
    //UX
    (function (h, o, t, j, a, r) {
      h.hj =
        h.hj ||
        function () {
          (h.hj.q = h.hj.q || []).push(arguments);
        };
      h._hjSettings = { hjid: 2841550, hjsv: 6 };
      a = o.getElementsByTagName("head")[0];
      r = o.createElement("script");
      r.async = 1;
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
    })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
    //Prüft Subscription Status
    firebase
      .firestore()
      .collection("users")
      .doc(this.userId)
      .get()
      .then(async (doc) => {
        this.subscriptionStatus = doc.data().subscriptionStatus;
      });
    //Holt Song-Groups aus Firebase
    db.collection(this.userId).onSnapshot((querySnapshot) => {
      this.refGroups = [];
      let refGroups = [];
      querySnapshot.forEach((doc) => {
        //Objekte innerhalb von "Songs"
        //    for (var i in doc.data().songs) {
        //         console.log(doc.data().songs[i])
        //     }

        const data = {
          //Daten Deklerations Modell
          id: doc.id,
          group_name: doc.data().group_name,
          songs: doc.data().songs,
          timestamp: doc.data().timestamp,
          isShared: doc.data().isShared,

          //Objekt Loop edit: geht hier nicht
        };
        refGroups.push(data);
      });
      //Get First Group & Limit Songs
      if (refGroups[0] != null) {
        this.freeGroupLength = Object.keys(refGroups[0].songs).length;
      }

      var sort = function sortByKey(array, key) {
        return array.sort(function (a, b) {
          var x = a[key];
          var y = b[key];
          return x < y ? -1 : x > y ? 1 : 0;
        });
      };
      //Initate sort & push into Array
      if (this.orderByDate == true) {
        this.refGroups = sort(refGroups, "timestamp").reverse();
      } else {
        this.refGroups = sort(refGroups, "group_name");
      }
    });

    bus.$on("ToastTrigger", this.toast);
    bus.$on("PlayPause", this.PlayPause);
    //Update every tick
    this.player.ontimeupdate = () => {
      //generawte playtime
      this.generateTime();
      bus.$on("clickProgress", async (e) => {
        this.player.pause();
        await bus.$on("progress", (f) => {
          this.progress = f;
        });
        this.updateBar(e.pageX);
      });
    };
    bus.$on("PrevSong", (a) => {
      if (a == true) {
        this.prevTrack();
      } else {
        null;
      }
    });
    bus.$on("NextSong", (a) => {
      if (a == true) {
        this.nextTrack();
      } else {
        null;
      }
    });
    bus.$on("FastForward", (a) => {
      if (a == true) {
        this.fastForward();
      } else {
        null;
      }
    });
    bus.$on("FastReverse", (a) => {
      if (a == true) {
        this.fastReverse();
      } else {
        null;
      }
    });
    this.player.onended = () => {
      this.nextTrack();
    };
  },
  computed: {
    myData() {
      return this.$data;
    },
  },
};
</script>

<style lang="scss">
.button_bg_dark {
  background-color: #404664;
  color: #f0f3fa;
  border-color: #404664;
}
.button_bg_dark:hover {
  background-color: #383d57;
  color: #f0f3fa;
  border-color: #383d57;
}
.button_bg_special {
  background-color: #6a73a0;
  border-color: #6a73a0;
  color: #f0f3fa;
}
.button_bg_special:hover {
  background-color: #586089;
  border-color: #586089;
  color: #f0f3fa;
}
.table {
  background-color: #f0f3fa !important;
}
.files {
  padding-top: 24px;
  margin-bottom: 60px;
}
.title {
  padding-top: 24px;
  color: #383d57;
}
.is-centered-table {
  text-align: center !important;
}
.message {
  margin-bottom: 36px;
}
.message-header {
  background-color: #383d57;
}
.message-body {
  background-color: #f0f3fa;
}
@media screen and (max-width: 768px) {
  .b-table
    .table-wrapper.has-mobile-cards
    tr:not(.detail):not(.is-empty):not(.table-footer)
    td {
    justify-content: flex-start !important;
  }
}

.b-table .table-wrapper.has-mobile-cards tr:not([class*="is-"]) {
  border-radius: 5px;
}
.files_mobile {
  margin-bottom: 150px;
  margin: 0 8px;
}
</style>
