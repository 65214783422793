<template>
  <div id="register_bg">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8">
          <div class="card">
            <b-icon icon="account" size="is-large"></b-icon>
            <h3>Reset your password</h3>
            <div class="card-body">
              <b-message type="is-danger is-light" v-if="error">{{
                error
              }}</b-message>
              <form action="#" @submit.prevent="submit">
                <div class="form-group row">
                  <label
                    for="email"
                    class="col-md-4 col-form-label text-md-right"
                    >Email</label
                  >

                  <div class="col-md-6">
                    <input
                      id="email"
                      type="email"
                      class="form-control"
                      name="email"
                      value
                      required
                      autofocus
                      v-model="form.email"
                    />
                  </div>
                </div>
                <div class="form-group row mb-0">
                  <div class="col-md-8 offset-md-4">
                    <button type="submit">Send E-Mail</button>
                  </div>
                </div>
              </form>
              <a href="/login">Sign in instead</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { loadScript } from "vue-plugin-load-script";

export default {
  //This is prob. useless and can be removed
  beforeCreated() {
    this.userId = firebase.auth().currentUser.uid;
  },

  data() {
    return {
      form: {
        email: "",
      },
      error: null,
      userID: "",
      subscriptionStatus: "",
      subscriptionId: "",
    };
  },
  mounted() {},
  created() {},

  methods: {
    submit() {
      firebase
        .auth()
        .sendPasswordResetEmail(this.form.email)
        .then(() => {
          this.error = "Password reset mail has been send!";
        })
        .catch((error) => {
          if (error.code == "auth/user-not-found") {
            this.error = "This E-Mail does not exists.";
          } else if (error.code == "auth/invalid-email") {
            this.error = "Invalid E-Mail";
          } else if (error.code == "auth/email-already-in-use") {
            this.error =
              "The email address is already in use by another account.";
          } else {
            this.error = error.message;
          }
        });
    },
  },
};
</script>

<style scoped>
.card {
  width: 350px;
  margin: 0 auto;
  padding: 16px 24px 48px 24px;
  border-radius: 0 0 5px 5px;
  -webkit-box-shadow: 10px 10px 69px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 69px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 69px 0px rgba(0, 0, 0, 0.75);
}
#register_bg {
  background: url(../assets/reset_bg.jpg);
  height: 100vh;
  background-position: bottom center;
  background-size: cover;
}

h3 {
  padding-bottom: 24px;
  font-weight: 1000;
  font-size: 2em;
}

button {
  padding: 1rem 2rem !important;
  font-size: 0.8rem !important;
  color: #fff;
  background-color: #6a73a0;
  border: 1px solid #6a73a0;
  border-radius: 5px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  margin-bottom: 16px;
}
button:hover {
  cursor: pointer;
}

input,
select {
  background: #fff;
  border-color: #e6e6e6;
  color: #555;
  padding: 1rem 1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: initial;
  border: 1px solid #ced4da;
  border-top-color: rgb(206, 212, 218);
  border-top-width: 1px;
  border-right-color: rgb(206, 212, 218);
  border-right-width: 1px;
  border-bottom-color: rgb(206, 212, 218);
  border-bottom-width: 1px;
  border-left-color: rgb(206, 212, 218);
  border-left-width: 1px;
  border-top-color: rgb(206, 212, 218);
  border-top-width: 1px;
  border-right-color: rgb(206, 212, 218);
  border-right-width: 1px;
  border-bottom-color: rgb(206, 212, 218);
  border-bottom-width: 1px;
  border-left-color: rgb(206, 212, 218);
  border-left-width: 1px;
  width: 100%;
  transition: background-color 0.2s linear;
  margin-bottom: 16px;
}

input:focus {
  background: #f9f9f9;
  border-color: #e6e6e6;
}
label,
a {
  display: inline-block;
  margin-bottom: 0.5rem;
  font-size: 0.8rem;
  color: #555;
}
</style>
