var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('div',{staticClass:"container files is-hidden-mobile"},[_c('h2',{staticClass:"title",staticStyle:{"display":"inline-block"}},[_vm._v("Playback Group:")]),_c('b-message',{directives:[{name:"show",rawName:"v-show",value:(this.refGroups.length == 0),expression:"this.refGroups.length == 0"}]},[_c('b',[_vm._v("It's quite empty here.")]),_vm._v(" Add groups by entering a group name and press enter.")]),_c('div',_vm._l((_vm.refGroups),function(group){return _c('div',{key:group.songs},[_c('b-message',{attrs:{"title":group.group_name,"aria-close-label":"Close message","closable":false}},[_c('div',{staticClass:"b-table"},[_c('div',{staticClass:"table-wrapper has-mobile-cards"},[_c('table',{staticClass:"table"},[_c('thead',[_c('tr',[_c('th',{staticStyle:{"width":"40px"}},[_vm._v("ID")]),_c('th',{staticStyle:{"width":"600px"}},[_vm._v("Song Name")]),_c('th',[_c('div',{staticClass:"th-wrap is-centered"},[_vm._v("Action")])]),_c('th',[_vm._v("Player")])])]),_c('tbody',_vm._l((_vm.orderChildren(group.songs)),function(songs){return _c('tr',{key:songs.song_name},[_c('td',[_vm._v(_vm._s(songs.song_id))]),_c('td',[_vm._v(_vm._s(songs.song_name))]),_c('td',{staticStyle:{"text-align":"center"}}),_c('td',[_c('b-button',{attrs:{"type":"is-dark","rounded":"","icon-right":"play"},on:{"click":function($event){_vm.PlaySong(
                            songs.song_src,
                            songs.song_name,
                            songs.song_id
                          );
                          _vm.CreatePlaylist(group.id);}}})],1)])}),0)])])])])],1)}),0),_c('Playbar',{attrs:{"playstate":this.playback,"songname":this.playername,"playtime":this.playtime,"duration":this.duration,"barWidth":this.barWidth}})],1),_c('div',{staticClass:"container files is-hidden-desktop files_mobile"},[_c('h2',{staticClass:"title"},[_vm._v("Playback Groups:")]),_c('div',_vm._l((_vm.refGroups),function(group){return _c('div',{key:group.songs},[_c('b-message',{attrs:{"title":group.group_name,"aria-close-label":"Close message","closable":false}},[_c('div',{staticClass:"b-table"},[_c('div',{staticClass:"table-wrapper has-mobile-cards"},[_c('table',{staticClass:"table"},[_c('thead',[_c('tr',[_c('th',{staticStyle:{"width":"600px"}},[_vm._v("Song Name")])])]),_c('tbody',_vm._l((_vm.orderChildren(group.songs)),function(songs){return _c('tr',{key:songs.song_name},[_c('td',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.PlaySong(
                          songs.song_src,
                          songs.song_name,
                          songs.song_id
                        );
                        _vm.CreatePlaylist(group.id);}}},[_c('b-icon',{attrs:{"icon":"play"}}),_vm._v(" "+_vm._s(songs.song_name)+" ")],1)])}),0)])])])])],1)}),0),_c('Playbar',{attrs:{"playstate":this.playback,"songname":this.playername,"playtime":this.playtime,"duration":this.duration,"barWidth":this.barWidth}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }