<template>
  <div>
    <Header></Header>
    <div class="container">
      <h2 class="title pb-5">Subscription-Settings:</h2>
      <p>
        Your current subscription plan is
        <span class="sub-level" v-if="subscriptionStatus == '658305'"
          >Anual</span
        >
        <span class="sub-level" v-else-if="subscriptionStatus == '658306'"
          >Monthly</span
        >
        <span class="sub-level" v-else>Free</span>.
      </p>
      <button
        @click="isUpgradeModalActive = !isUpgradeModalActive"
        v-if="subscriptionStatus != free"
        class="button button_bg_dark mt-5"
      >
        Upgrade
      </button>
      <button
        v-else
        @click="isUpgradeModalActive = !isUpgradeModalActive"
        class="button button_bg_dark mt-5"
      >
        Upgrade
      </button>
      <button
        v-show="subscriptionStatus != 'free'"
        class="button button_bg_dark mt-5 ml-5"
      >
        Edit Subscription
      </button>
      <h2 class="title mt-5">Account-Settings:</h2>
      <a target="_blank" href="https://forms.gle/bMBnTpNYm2NikFir7"
        ><button class="button button_bg_dark mt-5 mr-5">
          Request a feature
        </button></a
      >
      <a href="/reset"
        ><button class="button button_bg_dark mt-5">Edit Password</button></a
      >
      <a target="_blank" href="https://forms.gle/eTvGe1ZbDx72Qb4R6"
        ><button class="button is-danger mt-5 ml-5">Delete Account</button></a
      >
    </div>
    <!-- Upgrade Model -->
    <b-modal v-model="isUpgradeModalActive" scroll="keep">
      <div class="pricing-table">
        <div class="pricing-plan">
          <div class="plan-header">Starter</div>
          <div class="plan-price">
            <span class="plan-price-amount"
              ><span class="plan-price-currency"></span>Free</span
            >
          </div>
          <div class="plan-items">
            <div class="plan-item">Check out RefPlay!</div>
            <div class="plan-item">1 Group</div>
            <div class="plan-item">3 Songs</div>
            <div class="plan-item">-</div>
          </div>
          <div class="plan-footer">
            <button
              v-show="subscriptionStatus == 'free'"
              class="button is-fullwidth"
              disabled="disabled"
            >
              Current plan
            </button>
            <button
              v-show="subscriptionStatus != 'free'"
              @click="cancelSubscription()"
              class="button is-fullwidth"
            >
              Choose
            </button>
          </div>
        </div>

        <div class="pricing-plan is-warning">
          <div class="plan-header">Monthly Plan</div>
          <div class="plan-price">
            <span class="plan-price-amount"
              ><span class="plan-price-currency">$</span>7,99</span
            >/month
          </div>
          <div class="plan-items">
            <div class="plan-item">Unlimited Groups</div>
            <div class="plan-item">Unlimited Songs</div>
            <div class="plan-item">Share Groups</div>
          </div>
          <div class="plan-footer">
            <button
              v-show="subscriptionStatus == 'free'"
              @click="chooseMonthly()"
              class="button is-fullwidth"
            >
              Choose
            </button>
            <button
              v-show="subscriptionStatus == '658305'"
              @click="downgradeMonthly()"
              class="button is-fullwidth"
            >
              Choose
            </button>
            <button
              v-show="subscriptionStatus == '658306'"
              class="button is-fullwidth"
              disabled="disabled"
            >
              Current plan
            </button>
          </div>
        </div>

        <div class="pricing-plan is-active">
          <div class="plan-header">Anual Plan</div>
          <div class="plan-price">
            <span class="plan-price-amount"
              ><span class="plan-price-currency">$</span>69</span
            >/year
          </div>
          <div class="plan-items">
            <div class="plan-item">Everything from Monthly</div>
            <div class="plan-item">Enstruments Office Hours</div>
            <div class="plan-item">Save 20%</div>
          </div>
          <div class="plan-footer">
            <button
              v-show="subscriptionStatus == 'free'"
              @click="chooseYearly()"
              class="button is-fullwidth"
            >
              Choose
            </button>
            <button
              v-show="subscriptionStatus == '658306'"
              @click="upgradeYearly()"
              class="button is-fullwidth"
            >
              Choose
            </button>
            <button
              v-show="subscriptionStatus == '658305'"
              class="button is-fullwidth"
              disabled="disabled"
            >
              Current plan
            </button>
          </div>
        </div>
      </div>
      <p class="">*Prices may change depending on your location and taxes.</p>
    </b-modal>

    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { loadScript } from "vue-plugin-load-script";

export default {
  name: "Settings",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      subscriptionStatus: "",
      tempSubscriptionStatus: "",
      subscriptionId: "",
      userId: "",
      userEmail: "",
      isUpgradeModalActive: false,
    };
  },
  created() {
    //UX
    (function (h, o, t, j, a, r) {
      h.hj =
        h.hj ||
        function () {
          (h.hj.q = h.hj.q || []).push(arguments);
        };
      h._hjSettings = { hjid: 2841550, hjsv: 6 };
      a = o.getElementsByTagName("head")[0];
      r = o.createElement("script");
      r.async = 1;
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
    })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");

    this.userId = firebase.auth().currentUser.uid;
    this.userEmail = firebase.auth().currentUser.email;
    //Prüft Subscription Status
    firebase
      .firestore()
      .collection("users")
      .doc(this.userId)
      .get()
      .then(async (doc) => {
        this.subscriptionStatus = doc.data().subscriptionStatus;
        this.subscriptionId = doc.data().subscriptionId;
      });
  },
  mounted() {
    loadScript("https://cdn.paddle.com/paddle/paddle.js")
      .then(() => {
        // Script is loaded, do something
        Paddle.Setup({
          vendor: 124219,
          eventCallback: (data) => {
            // The data.event will specify the event type
            if (data.event === "Checkout.Complete") {
              console.log(data.eventData); // Data specifics on the event
              if (!data.eventData.flagged) {
                let checkoutId = data.eventData.checkout.id;
                Paddle.Order.details(checkoutId, function (data) {
                  // Order data, downloads, receipts etc... available within 'data' variable.
                  this.subscriptionId = data.subscriptionId;
                  this.updateUser();
                });
              }
              this.submit();
            } else if (data.event === "Checkout.Close") {
              console.log(data.eventData); // Data specifics on the event
            }
          },
        });
      })
      .catch(() => {
        // Failed to fetch script
      });
  },
  methods: {
    chooseMonthly() {
      this.tempSubscriptionStatus = "658306";
      Paddle.Checkout.open({
        product: 658306,
        email: this.userEmail,
      });
    },
    chooseYearly() {
      this.tempSubscriptionStatus = "658305";
      Paddle.Checkout.open({
        product: 658305,
        email: this.userEmail,
      });
    },
    async updateUser() {
      await firebase.firestore().collection("users").doc(this.userId).set({
        subscriptionStatus: this.tempSubscriptionStatus,
        subscriptionId: this.subscriptionId,
      });
    },
  },
  downgradeMonthly() {
    var data =
      "vendor_id=124219&vendor_auth_code=45c806a44e72ed60d6071f1b75513d7b6eba81f1472418ab86&subscription_id=" +
      this.subscriptionId +
      "&plan_id=658306";

    var xhr = new XMLHttpRequest();
    xhr.withCredentials = true;

    xhr.addEventListener("readystatechange", function () {
      if (this.readyState === this.DONE) {
        console.log(this.responseText);
        this.tempSubscriptionStatus = "658306";
        this.updateUser();
      }
    });

    xhr.open(
      "POST",
      "https://vendors.paddle.com/api/2.0/subscription/users/update"
    );
    xhr.setRequestHeader("content-type", "application/x-www-form-urlencoded");

    xhr.send(data);
  },
  upgradeYearly() {
    var data =
      "vendor_id=124219&vendor_auth_code=45c806a44e72ed60d6071f1b75513d7b6eba81f1472418ab86&subscription_id=" +
      this.subscriptionId +
      "&plan_id=658305";

    var xhr = new XMLHttpRequest();
    xhr.withCredentials = true;

    xhr.addEventListener("readystatechange", function () {
      if (this.readyState === this.DONE) {
        console.log(this.responseText);
        this.tempSubscriptionStatus = "658305";
        this.updateUser();
      }
    });

    xhr.open(
      "POST",
      "https://vendors.paddle.com/api/2.0/subscription/users/update"
    );
    xhr.setRequestHeader("content-type", "application/x-www-form-urlencoded");

    xhr.send(data);
  },
  cancelSubscription() {
    let data =
      "vendor_id=124219&vendor_auth_code=45c806a44e72ed60d6071f1b75513d7b6eba81f1472418ab86&subscription_id=" +
      this.subscriptionId;

    let xhr = new XMLHttpRequest();
    xhr.withCredentials = true;

    xhr.addEventListener("readystatechange", function () {
      if (this.readyState === this.DONE) {
        console.log(this.responseText);
        this.tempSubscriptionStatus = "free";
        this.updateUser();
      }
    });

    xhr.open(
      "POST",
      "https://vendors.paddle.com/api/2.0/subscription/users_cancel"
    );
    xhr.setRequestHeader("content-type", "application/x-www-form-urlencoded");

    xhr.send(data);
  },
};
</script>
<style scoped>
p {
  font-size: 18px;
}
.sub-level {
  font-weight: bold;
  background: linear-gradient(90deg, #00c9ff 0%, #92fe9d 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
}
@-webkit-keyframes spinAround {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes spinAround {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
.pricing-table {
  display: flex;
  align-items: stretch;
  flex-direction: row;
  flex-flow: row wrap;
  justify-content: space-between;
}
.pricing-table.is-comparative .pricing-plan {
  border-radius: initial;
  margin: 0;
}
.pricing-table.is-comparative .pricing-plan:not(:last-child) {
  border-right: none;
}
.pricing-table.is-comparative .pricing-plan:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.pricing-table.is-comparative .pricing-plan:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.pricing-table.is-comparative .pricing-plan.is-active {
  border: 0.1rem solid #f5f5f5;
  border-radius: 4px;
}
.pricing-table .pricing-plan {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  background-color: #fff;
  border: 0.1rem solid #f5f5f5;
  border-radius: 4px;
  list-style-type: none;
  transition: 0.25s;
  margin: 0.5em;
}
.pricing-table .pricing-plan .plan-header {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: #fff;
  color: #4a4a4a;
  font-size: 1.25rem;
  font-weight: 600;
  padding: 0.75em;
  align-content: center;
  text-align: center;
}
.pricing-table .pricing-plan .plan-item {
  background: #f5f5f5;
  border-bottom: 0.1rem solid #fff;
  padding: 0.75em;
  align-content: center;
  text-align: center;
  font-size: 0.9em;
  color: #7a7a7a;
}
.pricing-table .pricing-plan .plan-price {
  background-color: #fff;
  color: #4a4a4a;
  font-size: 0.75rem;
  font-weight: 400;
  padding: 0.75em;
  align-content: center;
  text-align: center;
}
.pricing-table .pricing-plan .plan-price .plan-price-amount {
  font-size: 2.5rem;
  font-weight: 700;
}
.pricing-table
  .pricing-plan
  .plan-price
  .plan-price-amount
  .plan-price-currency {
  vertical-align: super;
  font-size: 1rem !important;
  font-weight: 400 !important;
  color: #4a4a4a;
  margin-right: 0.25rem;
}
.pricing-table .pricing-plan .plan-footer {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 0.75em;
  align-content: center;
  text-align: center;
  margin-top: auto;
}
.pricing-table .pricing-plan.is-active {
  box-shadow: 0 8px 12px 0 rgba(122, 122, 122, 0.2);
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}
.pricing-table .pricing-plan.is-active .plan-price .plan-price-amount {
  color: #00d1b2;
}
.pricing-table .pricing-plan.is-active .plan-footer .button {
  background-color: #00d1b2;
  border-color: transparent;
  color: #fff;
}
.pricing-table .pricing-plan.is-active .plan-footer .button.is-hovered,
.pricing-table .pricing-plan.is-active .plan-footer .button:hover {
  background-color: #00c4a7;
  border-color: transparent;
  color: #fff;
}
.pricing-table .pricing-plan.is-active .plan-footer .button.is-focused,
.pricing-table .pricing-plan.is-active .plan-footer .button:focus {
  border-color: transparent;
  box-shadow: 0 0 0.5em rgba(0, 209, 178, 0.25);
  color: #fff;
}
.pricing-table .pricing-plan.is-active .plan-footer .button.is-active,
.pricing-table .pricing-plan.is-active .plan-footer .button:active {
  background-color: #00b89c;
  border-color: transparent;
  color: #fff;
}
.pricing-table .pricing-plan.is-active .plan-footer .button[disabled] {
  background-color: #00d1b2;
  border-color: transparent;
}
.pricing-table .pricing-plan.is-white .plan-price .plan-price-amount {
  color: #fff;
}
.pricing-table .pricing-plan.is-white .plan-footer .button {
  background-color: #fff;
  border-color: transparent;
  color: #0a0a0a;
}
.pricing-table .pricing-plan.is-white .plan-footer .button.is-hovered,
.pricing-table .pricing-plan.is-white .plan-footer .button:hover {
  background-color: #f9f9f9;
  border-color: transparent;
  color: #0a0a0a;
}
.pricing-table .pricing-plan.is-white .plan-footer .button.is-focused,
.pricing-table .pricing-plan.is-white .plan-footer .button:focus {
  border-color: transparent;
  box-shadow: 0 0 0.5em rgba(255, 255, 255, 0.25);
  color: #0a0a0a;
}
.pricing-table .pricing-plan.is-white .plan-footer .button.is-active,
.pricing-table .pricing-plan.is-white .plan-footer .button:active {
  background-color: #f2f2f2;
  border-color: transparent;
  color: #0a0a0a;
}
.pricing-table .pricing-plan.is-white .plan-footer .button[disabled] {
  background-color: #fff;
  border-color: transparent;
  box-shadow: none;
}
.pricing-table .pricing-plan.is-black .plan-price .plan-price-amount {
  color: #0a0a0a;
}
.pricing-table .pricing-plan.is-black .plan-footer .button {
  background-color: #0a0a0a;
  border-color: transparent;
  color: #fff;
}
.pricing-table .pricing-plan.is-black .plan-footer .button.is-hovered,
.pricing-table .pricing-plan.is-black .plan-footer .button:hover {
  background-color: #040404;
  border-color: transparent;
  color: #fff;
}
.pricing-table .pricing-plan.is-black .plan-footer .button.is-focused,
.pricing-table .pricing-plan.is-black .plan-footer .button:focus {
  border-color: transparent;
  box-shadow: 0 0 0.5em rgba(10, 10, 10, 0.25);
  color: #fff;
}
.pricing-table .pricing-plan.is-black .plan-footer .button.is-active,
.pricing-table .pricing-plan.is-black .plan-footer .button:active {
  background-color: #000;
  border-color: transparent;
  color: #fff;
}
.pricing-table .pricing-plan.is-black .plan-footer .button[disabled] {
  background-color: #0a0a0a;
  border-color: transparent;
  box-shadow: none;
}
.pricing-table .pricing-plan.is-light .plan-price .plan-price-amount {
  color: #f5f5f5;
}
.pricing-table .pricing-plan.is-light .plan-footer .button {
  background-color: #f5f5f5;
  border-color: transparent;
  color: #363636;
}
.pricing-table .pricing-plan.is-light .plan-footer .button.is-hovered,
.pricing-table .pricing-plan.is-light .plan-footer .button:hover {
  background-color: #eee;
  border-color: transparent;
  color: #363636;
}
.pricing-table .pricing-plan.is-light .plan-footer .button.is-focused,
.pricing-table .pricing-plan.is-light .plan-footer .button:focus {
  border-color: transparent;
  box-shadow: 0 0 0.5em rgba(245, 245, 245, 0.25);
  color: #363636;
}
.pricing-table .pricing-plan.is-light .plan-footer .button.is-active,
.pricing-table .pricing-plan.is-light .plan-footer .button:active {
  background-color: #e8e8e8;
  border-color: transparent;
  color: #363636;
}
.pricing-table .pricing-plan.is-light .plan-footer .button[disabled] {
  background-color: #f5f5f5;
  border-color: transparent;
  box-shadow: none;
}
.pricing-table .pricing-plan.is-dark .plan-price .plan-price-amount {
  color: #363636;
}
.pricing-table .pricing-plan.is-dark .plan-footer .button {
  background-color: #363636;
  border-color: transparent;
  color: #f5f5f5;
}
.pricing-table .pricing-plan.is-dark .plan-footer .button.is-hovered,
.pricing-table .pricing-plan.is-dark .plan-footer .button:hover {
  background-color: #2f2f2f;
  border-color: transparent;
  color: #f5f5f5;
}
.pricing-table .pricing-plan.is-dark .plan-footer .button.is-focused,
.pricing-table .pricing-plan.is-dark .plan-footer .button:focus {
  border-color: transparent;
  box-shadow: 0 0 0.5em rgba(54, 54, 54, 0.25);
  color: #f5f5f5;
}
.pricing-table .pricing-plan.is-dark .plan-footer .button.is-active,
.pricing-table .pricing-plan.is-dark .plan-footer .button:active {
  background-color: #292929;
  border-color: transparent;
  color: #f5f5f5;
}
.pricing-table .pricing-plan.is-dark .plan-footer .button[disabled] {
  background-color: #363636;
  border-color: transparent;
  box-shadow: none;
}
.pricing-table .pricing-plan.is-primary .plan-price .plan-price-amount {
  color: #00d1b2;
}
.pricing-table .pricing-plan.is-primary .plan-footer .button {
  background-color: #00d1b2;
  border-color: transparent;
  color: #fff;
}
.pricing-table .pricing-plan.is-primary .plan-footer .button.is-hovered,
.pricing-table .pricing-plan.is-primary .plan-footer .button:hover {
  background-color: #00c4a7;
  border-color: transparent;
  color: #fff;
}
.pricing-table .pricing-plan.is-primary .plan-footer .button.is-focused,
.pricing-table .pricing-plan.is-primary .plan-footer .button:focus {
  border-color: transparent;
  box-shadow: 0 0 0.5em rgba(0, 209, 178, 0.25);
  color: #fff;
}
.pricing-table .pricing-plan.is-primary .plan-footer .button.is-active,
.pricing-table .pricing-plan.is-primary .plan-footer .button:active {
  background-color: #00b89c;
  border-color: transparent;
  color: #fff;
}
.pricing-table .pricing-plan.is-primary .plan-footer .button[disabled] {
  background-color: #00d1b2;
  border-color: transparent;
  box-shadow: none;
}
.pricing-table .pricing-plan.is-link .plan-price .plan-price-amount {
  color: #3273dc;
}
.pricing-table .pricing-plan.is-link .plan-footer .button {
  background-color: #3273dc;
  border-color: transparent;
  color: #fff;
}
.pricing-table .pricing-plan.is-link .plan-footer .button.is-hovered,
.pricing-table .pricing-plan.is-link .plan-footer .button:hover {
  background-color: #276cda;
  border-color: transparent;
  color: #fff;
}
.pricing-table .pricing-plan.is-link .plan-footer .button.is-focused,
.pricing-table .pricing-plan.is-link .plan-footer .button:focus {
  border-color: transparent;
  box-shadow: 0 0 0.5em rgba(50, 115, 220, 0.25);
  color: #fff;
}
.pricing-table .pricing-plan.is-link .plan-footer .button.is-active,
.pricing-table .pricing-plan.is-link .plan-footer .button:active {
  background-color: #2366d1;
  border-color: transparent;
  color: #fff;
}
.pricing-table .pricing-plan.is-link .plan-footer .button[disabled] {
  background-color: #3273dc;
  border-color: transparent;
  box-shadow: none;
}
.pricing-table .pricing-plan.is-info .plan-price .plan-price-amount {
  color: #209cee;
}
.pricing-table .pricing-plan.is-info .plan-footer .button {
  background-color: #209cee;
  border-color: transparent;
  color: #fff;
}
.pricing-table .pricing-plan.is-info .plan-footer .button.is-hovered,
.pricing-table .pricing-plan.is-info .plan-footer .button:hover {
  background-color: #1496ed;
  border-color: transparent;
  color: #fff;
}
.pricing-table .pricing-plan.is-info .plan-footer .button.is-focused,
.pricing-table .pricing-plan.is-info .plan-footer .button:focus {
  border-color: transparent;
  box-shadow: 0 0 0.5em rgba(32, 156, 238, 0.25);
  color: #fff;
}
.pricing-table .pricing-plan.is-info .plan-footer .button.is-active,
.pricing-table .pricing-plan.is-info .plan-footer .button:active {
  background-color: #118fe4;
  border-color: transparent;
  color: #fff;
}
.pricing-table .pricing-plan.is-info .plan-footer .button[disabled] {
  background-color: #209cee;
  border-color: transparent;
  box-shadow: none;
}
.pricing-table .pricing-plan.is-success .plan-price .plan-price-amount {
  color: #23d160;
}
.pricing-table .pricing-plan.is-success .plan-footer .button {
  background-color: #23d160;
  border-color: transparent;
  color: #fff;
}
.pricing-table .pricing-plan.is-success .plan-footer .button.is-hovered,
.pricing-table .pricing-plan.is-success .plan-footer .button:hover {
  background-color: #22c65b;
  border-color: transparent;
  color: #fff;
}
.pricing-table .pricing-plan.is-success .plan-footer .button.is-focused,
.pricing-table .pricing-plan.is-success .plan-footer .button:focus {
  border-color: transparent;
  box-shadow: 0 0 0.5em rgba(35, 209, 96, 0.25);
  color: #fff;
}
.pricing-table .pricing-plan.is-success .plan-footer .button.is-active,
.pricing-table .pricing-plan.is-success .plan-footer .button:active {
  background-color: #20bc56;
  border-color: transparent;
  color: #fff;
}
.pricing-table .pricing-plan.is-success .plan-footer .button[disabled] {
  background-color: #23d160;
  border-color: transparent;
  box-shadow: none;
}
.pricing-table .pricing-plan.is-warning .plan-price .plan-price-amount {
  color: #ffdd57;
}
.pricing-table .pricing-plan.is-warning .plan-footer .button {
  background-color: #ffdd57;
  border-color: transparent;
  color: rgba(0, 0, 0, 0.7);
}
.pricing-table .pricing-plan.is-warning .plan-footer .button.is-hovered,
.pricing-table .pricing-plan.is-warning .plan-footer .button:hover {
  background-color: #ffdb4a;
  border-color: transparent;
  color: rgba(0, 0, 0, 0.7);
}
.pricing-table .pricing-plan.is-warning .plan-footer .button.is-focused,
.pricing-table .pricing-plan.is-warning .plan-footer .button:focus {
  border-color: transparent;
  box-shadow: 0 0 0.5em rgba(255, 221, 87, 0.25);
  color: rgba(0, 0, 0, 0.7);
}
.pricing-table .pricing-plan.is-warning .plan-footer .button.is-active,
.pricing-table .pricing-plan.is-warning .plan-footer .button:active {
  background-color: #ffd83d;
  border-color: transparent;
  color: rgba(0, 0, 0, 0.7);
}
.pricing-table .pricing-plan.is-warning .plan-footer .button[disabled] {
  background-color: #ffdd57;
  border-color: transparent;
  box-shadow: none;
}
.pricing-table .pricing-plan.is-danger .plan-price .plan-price-amount {
  color: #ff3860;
}
.pricing-table .pricing-plan.is-danger .plan-footer .button {
  background-color: #ff3860;
  border-color: transparent;
  color: #fff;
}
.pricing-table .pricing-plan.is-danger .plan-footer .button.is-hovered,
.pricing-table .pricing-plan.is-danger .plan-footer .button:hover {
  background-color: #ff2b56;
  border-color: transparent;
  color: #fff;
}
.pricing-table .pricing-plan.is-danger .plan-footer .button.is-focused,
.pricing-table .pricing-plan.is-danger .plan-footer .button:focus {
  border-color: transparent;
  box-shadow: 0 0 0.5em rgba(255, 56, 96, 0.25);
  color: #fff;
}
.pricing-table .pricing-plan.is-danger .plan-footer .button.is-active,
.pricing-table .pricing-plan.is-danger .plan-footer .button:active {
  background-color: #ff1f4b;
  border-color: transparent;
  color: #fff;
}
.pricing-table .pricing-plan.is-danger .plan-footer .button[disabled] {
  background-color: #ff3860;
  border-color: transparent;
  box-shadow: none;
}
@media screen and (min-width: 769px), print {
  .pricing-table.is-comparative {
    margin: 0.5rem;
  }
  .pricing-table.is-comparative.is-features {
    display: none;
  }
  .pricing-table.is-comparative .plan-item::before {
    content: attr("data-feature");
  }
  .pricing-table.is-horizontal .pricing-plan {
    display: flex;
    flex-direction: row;
    flex-basis: 100%;
  }
  .pricing-table.is-horizontal .pricing-plan .plan-header {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .pricing-table.is-horizontal .pricing-plan .plan-price {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .pricing-table.is-horizontal .pricing-plan .plan-items {
    width: 100%;
    background-color: #f5f5f5;
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
    -webkit-column-gap: 0.1rem;
    column-gap: 0.1rem;
  }
  .pricing-table.is-horizontal .pricing-plan .plan-footer {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
  }
}
@media screen and (max-width: 768px) {
  .pricing-table.is-comparative .pricing-plan {
    margin: 0.5rem;
  }
  .pricing-table.is-comparative .pricing-plan.is-plan-first-mobile {
    order: -1;
  }
  .pricing-table.is-comparative .pricing-plan.is-features {
    display: none;
  }
  .pricing-table.is-comparative .pricing-plan .plan-item::before {
    content: attr(data-feature);
    display: block;
    font-weight: 600;
    font-size: 0.75rem;
    color: #b5b5b5;
  }
}
</style>
