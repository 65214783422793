<template>
  <div class="home">
    
    <Header/>
    <SharePlayer/>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue"
import SharePlayer from "@/components/SharePlayer.vue"

export default {
  name: "ShareView",
  components: {
    Header,
    SharePlayer,
  }
};
</script>
