<template>
  <b-modal
    :active.sync="isComponentModalActive"
    has-modal-card
    trap-focus
    :destroy-on-hide="true"
    aria-role="dialog"
    aria-modal
  >
    <Upload :group_name="group_name" />
  </b-modal>
</template>


<script>
import Upload from "@/components/Upload.vue";
import { bus } from "../main";

export default {
  props: {
    group_name: {
      type: String
    }
  },
  components: {
    Upload
  },
  data() {
    return {
      isComponentModalActive: false
    };
  },
  //Pass Clickevent to Modal
  created() {
    bus.$on("ModalTrigger", data => {
      this.isComponentModalActive = data;
    });
  }
};
</script>

<style>
</style>