<template>
  <section>
    <div class="container">
      <b-message
        v-show="this.dropFiles.length >= 3 && subscriptionStatus == 'free'"
        >You have reached the maximum amount of songs.
        <b><a href="/settings">Upgrade your subscription</a></b> if you need
        more.</b-message
      >
      <p style="color: #f0f3fa">Upload Status:</p>
      <b-progress
        :value="progressBar"
        format="percent"
        show-value
        type="is-info"
      ></b-progress>
      <b-field>
        <b-upload v-model="dropFiles" drag-drop multiple expanded>
          <section class="section">
            <div class="content has-text-centered">
              <p>
                <b-icon icon="upload" size="is-large"></b-icon>
              </p>
              <p>Drop your files here or click to select</p>
            </div>
          </section>
        </b-upload>
      </b-field>

      <div class="tags">
        <span
          v-for="(file, index) in dropFiles"
          :key="index"
          class="tag is-primary"
        >
          {{ file.name }}
          <button
            class="delete is-small"
            type="button"
            @click="deleteDropFile(index)"
          ></button>
        </span>
      </div>

      <b-button
        icon-left="upload"
        class="button button_bg_dark is-fullwidth"
        @click="detectFiles"
        v-model="progressBar"
        :disabled="this.dropFiles.length >= 3 && subscriptionStatus == 'free'"
        >Upload Files - {{ progressBar }}%</b-button
      >
    </div>
  </section>
</template>

<script>
// eslint-disable-next-line
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";

import { bus } from "../main";

export default {
  props: {
    group_name: {
      type: String,
    },
    subscriptionStatus: {
      type: String,
    },
  },
  beforeCreate() {
    this.userId = firebase.auth().currentUser.uid;
  },
  data() {
    return {
      file: {},
      dropFiles: [],
      uploadTask: "",
      progressBar: 0,
      SongNumber: 0,
      subscriptionStatus: "",
    };
  },
  methods: {
    deleteDropFile(index) {
      this.dropFiles.splice(index, 1);
    },
    detectFiles() {
      //Uploadfeld wird in einzelne Dateien verlegt
      Array.from(Array(this.dropFiles.length).keys()).map((x) => {
        //Define File als (1) File aus dem Upload-Feld
        let file = this.dropFiles[x];
        //Define Firebase-Storage
        let storageRef = firebase.storage().ref();
        //Upload File
        let uploadTask = storageRef
          .child(this.userId + "/" + "songs/" + file.name)
          .put(file);
        //Set Filename für später
        let FileName = file.name;
        //Progressbar
        uploadTask.on(
          firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
          (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded

            this.progressBar = Math.floor(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
          },
          null,
          //Resolve Promise
          () => {
            // Upload completed successfully, now we can get the download URL
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              let Download = downloadURL;
              //Triggert wenn URL verfügbar ist
              firebase
                .firestore()
                .collection(this.userId)
                .doc(this.group_name)
                .get()
                .then((doc) => {
                  if (doc.exists) {
                    this.SongNumber = Object.keys(doc.data().songs).length + 1;
                    //Warten bis alles fertig ist
                    firebase
                      .firestore()
                      .collection(this.userId)
                      .doc(this.group_name)
                      .update({
                        //Song ID
                        ["songs.song_" + this.SongNumber + ".song_id"]:
                          this.SongNumber,
                        //Song Name
                        ["songs.song_" + this.SongNumber + ".song_name"]:
                          FileName,
                        //Song Src
                        ["songs.song_" + this.SongNumber + ".song_src"]:
                          Download,
                      });
                    bus.$emit("ModalTrigger", false),
                      bus.$emit("ToastTrigger", true);
                  } else {
                    console.log("Error. Doc does not exist");
                  }
                });
            });
          }
        );
      });
    },
  },
  created() {
    firebase
      .firestore()
      .collection("users")
      .doc(this.userId)
      .get()
      .then(async (doc) => {
        this.subscriptionStatus = doc.data().subscriptionStatus;
      });
  },
};
</script>
<style>
.upload .upload-draggable:hover.is-primary,
.upload .upload-draggable.is-hovered.is-primary {
  border-color: rgba(255, 117, 117, 0.05);
  background: #40466434;
}
.button_bg_special {
  background-color: #648de5;
  border-color: #648de5;
  color: #f0f3fa;
}
.progress {
  background-color: #f0f3fa;
  -webkit-appearance: none;
  appearance: none;
}
.progress::-moz-progress-bar,
.progress::-webkit-progress-bar {
  background-color: #404664;
}
progress[value] {
  -webkit-appearance: none;
  appearance: none;
}
.button:focus,
.button.is-focused {
  color: #f0f3fa;
}
</style>
